// English phrases

export default {
  com_ui_examples: 'Examples',
  com_ui_new_chat: 'New chat',
  com_ui_example_quantum_computing: 'Explain quantum computing in simple terms',
  com_ui_example_10_year_old_b_day: 'Got any creative ideas for a 10 year old\'s birthday?',
  com_ui_example_http_in_js: 'How do I make an HTTP request in Javascript?',
  com_ui_capabilities: 'Capabilities',
  com_ui_capability_remember: 'Remembers what user said earlier in the conversation',
  com_ui_capability_correction: 'Allows user to provide follow-up corrections',
  com_ui_capability_decline_requests: 'Trained to decline inappropriate requests',
  com_ui_limitations: 'Limitations',
  com_ui_limitation_incorrect_info: 'May occasionally generate incorrect information',
  com_ui_limitation_harmful_biased:
    'May occasionally produce harmful instructions or biased content',
  com_ui_limitation_limited_2021: 'Limited knowledge of world and events after 2021',
  com_ui_input: 'Input',
  com_ui_close: 'Close',
  com_ui_model: 'Model',
  com_ui_select_model: 'Select a model',
  com_ui_use_prompt: 'Use prompt',
  com_ui_prev: 'Prev',
  com_ui_next: 'Next',
  com_ui_prompt_templates: 'Prompt Templates',
  com_ui_hide_prompt_templates: 'Hide Prompt Templates',
  com_ui_showing: 'Showing',
  com_ui_of: 'of',
  com_ui_entries: 'Entries',
  com_ui_pay_per_call: 'All AI conversations in one place. Pay per call and not per month',
  com_auth_error_login:
    'Unable to login with the information provided. Please check your credentials and try again.',
  com_auth_no_account: 'Don\'t have an account?',
  com_auth_sign_up: 'Sign up',
  com_auth_sign_in: 'Sign in',
  com_auth_google_login: 'Login with Google',
  com_auth_github_login: 'Login with Github',
  com_auth_discord_login: 'Login with Discord',
  com_auth_email: 'Email',
  com_auth_email_required: 'Email is required',
  com_auth_email_min_length: 'Email must be at least 6 characters',
  com_auth_email_max_length: 'Email should not be longer than 120 characters',
  com_auth_email_pattern: 'You must enter a valid email address',
  com_auth_email_address: 'Email address',
  com_auth_password: 'Password',
  com_auth_password_required: 'Password is required',
  com_auth_password_min_length: 'Password must be at least 8 characters',
  com_auth_password_max_length: 'Password must be less than 128 characters',
  com_auth_password_forgot: 'Forgot Password?',
  com_auth_password_confirm: 'Confirm password',
  com_auth_password_not_match: 'Passwords do not match',
  com_auth_continue: 'Continue',
  com_auth_create_account: 'Create your account',
  com_auth_error_create:
    'There was an error attempting to register your account. Please try again.',
  com_auth_full_name: 'Full name',
  com_auth_name_required: 'Name is required',
  com_auth_name_min_length: 'Name must be at least 3 characters',
  com_auth_name_max_length: 'Name must be less than 80 characters',
  com_auth_username: 'Username',
  com_auth_username_required: 'Username is required',
  com_auth_username_min_length: 'Username must be at least 3 characters',
  com_auth_username_max_length: 'Username must be less than 20 characters',
  com_auth_already_have_account: 'Already have an account?',
  com_auth_login: 'Login',
  com_auth_reset_password: 'Reset your password',
  com_auth_click: 'Click',
  com_auth_here: 'HERE',
  com_auth_to_reset_your_password: 'to reset your password.',
  com_auth_reset_password_link_sent: 'Email Sent',
  com_auth_reset_password_email_sent:
    'An email has been sent to you with further instructions to reset your password.',
  com_auth_error_reset_password:
    'There was a problem resetting your password. There was no user found with the email address provided. Please try again.',
  com_auth_reset_password_success: 'Password Reset Success',
  com_auth_login_with_new_password: 'You may now login with your new password.',
  com_auth_error_invalid_reset_token: 'This password reset token is no longer valid.',
  com_auth_click_here: 'Click here',
  com_auth_to_try_again: 'to try again.',
  com_auth_submit_registration: 'Submit registration',
  com_auth_welcome_back: 'Welcome back',
  com_endpoint_bing_enable_sydney: 'Enable Sydney',
  com_endpoint_bing_to_enable_sydney: 'To enable Sydney',
  com_endpoint_bing_jailbreak: 'Jailbreak',
  com_endpoint_bing_context_placeholder:
    'Bing can use up to 7k tokens for \'context\', which it can reference for the conversation. The specific limit is not known but may run into errors exceeding 7k tokens',
  com_endpoint_bing_system_message_placeholder:
    'WARNING: Misuse of this feature can get you BANNED from using Bing! Click on \'System Message\' for full instructions and the default message if omitted, which is the \'Sydney\' preset that is considered safe.',
  com_endpoint_system_message: 'System Message',
  com_endpoint_default_blank: 'default: blank',
  com_endpoint_default_false: 'default: false',
  com_endpoint_default_creative: 'default: creative',
  com_endpoint_default_empty: 'default: empty',
  com_endpoint_default_with_num: 'default: {0}',
  com_endpoint_context: 'Context',
  com_endpoint_tone_style: 'Tone Style',
  com_endpoint_token_count: 'Token count',
  com_endpoint_output: 'Output',
  com_endpoint_google_temp:
    'Higher values = more random, while lower values = more focused and deterministic. We recommend altering this or Top P but not both.',
  com_endpoint_google_topp:
    'Top-p changes how the model selects tokens for output. Tokens are selected from most K (see topK parameter) probable to least until the sum of their probabilities equals the top-p value.',
  com_endpoint_google_topk:
    'Top-k changes how the model selects tokens for output. A top-k of 1 means the selected token is the most probable among all tokens in the model\'s vocabulary (also called greedy decoding), while a top-k of 3 means that the next token is selected from among the 3 most probable tokens (using temperature).',
  com_endpoint_google_maxoutputtokens:
    ' 	Maximum number of tokens that can be generated in the response. Specify a lower value for shorter responses and a higher value for longer responses.',
  com_endpoint_google_custom_name_placeholder: 'Set a custom name for PaLM2',
  com_endpoint_google_prompt_prefix_placeholder:
    'Set custom instructions or context. Ignored if empty.',
  com_endpoint_custom_name: 'Custom Name',
  com_endpoint_prompt_prefix: 'Prompt Prefix',
  com_endpoint_temperature: 'Temperature',
  com_endpoint_default: 'default',
  com_endpoint_top_p: 'Top P',
  com_endpoint_top_k: 'Top K',
  com_endpoint_max_output_tokens: 'Max Output Tokens',
  com_endpoint_openai_temp:
    'Higher values = more random, while lower values = more focused and deterministic. We recommend altering this or Top P but not both.',
  com_endpoint_openai_max:
    'The max tokens to generate. The total length of input tokens and generated tokens is limited by the model\'s context length.',
  com_endpoint_openai_topp:
    'An alternative to sampling with temperature, called nucleus sampling, where the model considers the results of the tokens with top_p probability mass. So 0.1 means only the tokens comprising the top 10% probability mass are considered. We recommend altering this or temperature but not both.',
  com_endpoint_openai_freq:
    'Number between -2.0 and 2.0. Positive values penalize new tokens based on their existing frequency in the text so far, decreasing the model\'s likelihood to repeat the same line verbatim.',
  com_endpoint_openai_pres:
    'Number between -2.0 and 2.0. Positive values penalize new tokens based on whether they appear in the text so far, increasing the model\'s likelihood to talk about new topics.',
  com_endpoint_openai_custom_name_placeholder: 'Set a custom name for ChatGPT',
  com_endpoint_openai_prompt_prefix_placeholder:
    'Set custom instructions to include in System Message. Default: none',
  com_endpoint_anthropic_temp:
    'Ranges from 0 to 1. Use temp closer to 0 for analytical / multiple choice, and closer to 1 for creative and generative tasks. We recommend altering this or Top P but not both.',
  com_endpoint_anthropic_topp:
    'Top-p changes how the model selects tokens for output. Tokens are selected from most K (see topK parameter) probable to least until the sum of their probabilities equals the top-p value.',
  com_endpoint_anthropic_topk:
    'Top-k changes how the model selects tokens for output. A top-k of 1 means the selected token is the most probable among all tokens in the model\'s vocabulary (also called greedy decoding), while a top-k of 3 means that the next token is selected from among the 3 most probable tokens (using temperature).',
  com_endpoint_anthropic_maxoutputtokens:
    'Maximum number of tokens that can be generated in the response. Specify a lower value for shorter responses and a higher value for longer responses.',
  com_endpoint_frequency_penalty: 'Frequency Penalty',
  com_endpoint_presence_penalty: 'Presence Penalty',
  com_endpoint_plug_use_functions: 'Use Functions',
  com_endpoint_plug_skip_completion: 'Skip Completion',
  com_endpoint_disabled_with_tools: 'disabled with tools',
  com_endpoint_disabled_with_tools_placeholder: 'Disabled with Tools Selected',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder:
    'Set custom instructions to include in System Message. Default: none',
  com_endpoint_set_custom_name: 'Set a custom name, in case you can find this preset',
  com_endpoint_preset_name: 'Preset Name',
  com_endpoint_new_topic: 'New Topic',
  com_endpoint: 'Endpoint',
  com_endpoint_hide: 'Hide',
  com_endpoint_show: 'Show',
  com_endpoint_examples: ' Examples',
  com_endpoint_completion: 'Completion',
  com_endpoint_agent: 'Agent',
  com_endpoint_show_what_settings: 'Show {0} Settings',
  com_endpoint_save: 'Save',
  com_endpoint_export: 'Export',
  com_endpoint_save_as_preset: 'Save As Preset',
  com_endpoint_not_implemented: 'Not implemented',
  com_endpoint_edit_preset: 'Edit Preset',
  com_endpoint_no_presets: 'No preset yet',
  com_endpoint_not_available: 'No endpoint available',
  com_endpoint_clear_all: 'Clear All',
  com_endpoint_view_options: 'View Options',
  com_endpoint_save_convo_as_preset: 'Save Conversation as Preset',
  com_endpoint_my_preset: 'My Preset',
  com_endpoint_agent_model: 'Agent Model (Recommended: GPT-3.5)',
  com_endpoint_completion_model: 'Completion Model (Recommended: GPT-4)',
  com_endpoint_func_hover: 'Enable use of Plugins as OpenAI Functions',
  com_endpoint_skip_hover:
    'Enable skipping the completion step, which reviews the final answer and generated steps',
  com_endpoint_config_token: 'Config Token',
  com_nav_export_filename: 'Filename',
  com_nav_export_filename_placeholder: 'Set the filename',
  com_nav_export_type: 'Type',
  com_nav_export_include_endpoint_options: 'Include endpoint options',
  com_nav_enabled: 'Enabled',
  com_nav_not_supported: 'Not Supported',
  com_nav_export_all_message_branches: 'Export all message branches',
  com_nav_export_recursive_or_sequential: 'Recursive or sequential?',
  com_nav_export_recursive: 'Recursive',
  com_nav_export_conversation: 'Export conversation',
  com_nav_theme: 'Theme',
  com_nav_theme_system: 'System',
  com_nav_theme_dark: 'Dark',
  com_nav_theme_light: 'Light',
  com_nav_clear: 'Clear',
  com_nav_clear_all_chats: 'Clear all chats',
  com_nav_confirm_clear: 'Confirm Clear',
  com_nav_close_sidebar: 'Close sidebar',
  com_nav_open_sidebar: 'Open sidebar',
  com_nav_log_out: 'Log out',
  com_nav_user: 'USER',
  com_nav_clear_conversation: 'Clear conversations',
  com_nav_clear_conversation_confirm_message:
    'Are you sure you want to clear all conversations? This is irreversible.',
  com_nav_help_faq: 'Help & FAQ',
  com_nav_settings: 'Settings',
  com_nav_search_placeholder: 'Search messages',
  com_nav_setting_general: 'General',
  com_nav_language: 'Language',
  com_nav_lang_english: 'English',
  com_nav_lang_chinese: '中文',
  com_nav_lang_german: 'Deutsch',
  com_nav_lang_spanish: 'Español',
  com_nav_lang_french: 'Français ',
  com_nav_lang_italian: 'Italiano',
  com_nav_lang_brazilian_portuguese: 'Português Brasileiro',
};
